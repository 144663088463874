import React from 'react';
import Navigation from '../components/Navigation';
import Me from '../components/Me';
import MeLangage from '../components/MeLangage';

const About = () => {
    return (
        <div>
            <Navigation />
            <Me />
            <MeLangage />
        </div>
    );
};

export default About;