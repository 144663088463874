import React from 'react';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';


const Navigation = () => {

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className="navigation">
            <div className={`menu-toggle ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
                <FontAwesomeIcon icon={faBars} />
            </div>

            {isMobileMenuOpen && (
                <div className="mobile-menu">
                    <div className="mobile-menu-overlay" onClick={toggleMobileMenu}>
                        <ul className="mobile-menu-list">
                            <li>
                                <div className="mobile-box">
                                    <NavLink to="/about" className="mobile-menu-list-front">Qui suis-je ?</NavLink>
                                    <div className="mobile-menu-list-behind"></div>
                                </div>
                            </li>
                            <li>
                                <div className="mobile-box">
                                    <NavLink to="/quechoisir" className="mobile-menu-list-front">Que choisir ?</NavLink>
                                    <div className="mobile-menu-list-behind"></div>
                                </div>
                            </li>
                            <li>
                                <div className="mobile-box">
                                    <NavLink to="/home" className="mobile-menu-list-front">Accueil</NavLink>
                                    <div className="mobile-menu-list-behind"></div>
                                </div>
                            </li>
                            <li>
                                <div className="mobile-box">
                                    <NavLink to="/processus" className="mobile-menu-list-front">Processus</NavLink>
                                    <div className="mobile-menu-list-behind"></div>
                                </div>
                            </li>
                            <li>
                                <div className="mobile-box">
                                    <NavLink to="/contact" className="mobile-menu-list-front">Contact</NavLink>
                                    <div className="mobile-menu-list-behind"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            )}


            <ul className={isMobileMenuOpen ? "mobile-menu-open" : "mobile-menu-closed"}>
                <li>
                    <div className="nav-rectangle">
                        <NavLink to="/about" className="nav-item">Qui suis-je ?</NavLink>
                        <div className="rectangle-behind"></div>
                    </div>
                </li>
                <li>
                    <div className="nav-rectangle">
                        <NavLink to="/quechoisir" className="nav-item">Que choisir ?</NavLink>
                        <div className="rectangle-behind"></div>
                    </div>
                </li>

                <li className="nav-home">
                    <NavLink to="/home" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <FontAwesomeIcon icon={faHome} /></NavLink>
                </li>
                <li>
                    <div className="nav-rectangle">
                        <NavLink to="/processus" className="nav-item">Processus</NavLink>
                        <div className="rectangle-behind"></div>
                    </div>
                </li>
                <li>
                    <div className="nav-rectangle">
                        <NavLink to="/contact" className="nav-item">Contact</NavLink>
                        <div className="rectangle-behind"></div>
                    </div>

                </li>

            </ul>
        </div>
    );
};

export default Navigation;

