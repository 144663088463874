import React from 'react';
import Navigation from '../components/Navigation';
import Cubes from '../components/Cubes';

const Processus = () => {
    return (
        <div>
            <Navigation />
            <Cubes />
        </div>
    );
};

export default Processus;