import React from 'react';
// import { useState } from 'react';
import { NavLink } from 'react-router-dom';


const TarifWp = () => {

    // const [displayText, setDisplayText] = useState('prix');

    // const handleClick = () => {
        // setDisplayText('976€');
    // };

    return (
        <div className="wordpress">
            <ul>
                <li>
                    <p className="title">inclus :</p>
                </li>
                <li>
                    <div className="category">
                        <div className="cat-front">l'hebergement</div>
                        <div className="cat-behind"></div>
                        <p className="explanations">Vous aurez besoin d'un service d'hébergement web pour stocker
                            les fichiers de votre site et le rendre accessible en ligne.</p>
                    </div>

                </li>
                <li>
                    <div className="category">
                        <div className="cat-front">nom de domaine</div>
                        <div className="cat-behind"></div>
                        <p className="explanations">personnaliser le nom et choisir l’extension (.fr, .com, .org,...)</p>

                    </div>
                </li>
                <li>
                    <div className="category">
                        <div className="cat-front">template</div>
                        <div className="cat-behind"></div>
                        <p className="explanations">thème préconçu pour votre site en fonction de vos besoins et le style souhaité pour votre site.</p>
                    </div>
                </li>
                <li>
                    <div className="category">
                        <div className="cat-front">plugin et extension</div>
                        <div className="cat-behind"></div>
                        <p className="explanations">Pour ajouter des fonctionnalités spécifiques à votre site.</p>

                    </div>
                </li>
                <li>
                    <div className="category">
                        <div className="cat-front">conception</div>
                        <div className="cat-behind"></div>
                        <p className="explanations">developpement du site, du cahier des charges au déploiement.</p>

                    </div>
                </li>
                <li>
                    <div className="category">
                        <div className="cat-front">tva</div>
                        <div className="cat-behind"></div>
                        <p className="explanations">taxe de freelance.</p>

                    </div>
                </li>
                <li className='price'>
                    {/* <div className="price-front" onClick={handleClick}>{displayText}</div> */}
                    <NavLink to="/contact" className="devis">
                        <div className="price-front">devis</div>
                        <div className="price-behind"></div>
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default TarifWp;