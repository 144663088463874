import React from 'react';
import { NavLink } from 'react-router-dom';

import screenWp from "../assets/imgs/screenshotWp.png";
import screenReact from "../assets/imgs/screenshotReact.png";

const Theme = () => {
	return (
		<div className="frame">
			<div className="right-side">
				<NavLink to="/home">
					<img src={screenReact} alt="screenReact" />
				</NavLink>
			</div>
			<div className="middle-text">
				<h1>Choisissez votre thème</h1>
			</div>
			<div className="left-side">
				<a href="https://www.webistart.go.yj.fr/">
					<img src={screenWp} alt="screenWp" />
				</a>
			</div>
		</div>
	);
};

export default Theme;