import React from 'react';
import { NavLink } from 'react-router-dom';

const Question = () => {
	return (
		<div className="question-box">
			<NavLink to="/quechoisir" className="box-front">?</NavLink>
			<div className="box-behind"></div>
			<NavLink to="/quechoisir" className="question">Choisir Wordpress ou React pour son site ?</NavLink>
		</div>
	);
};

export default Question;