import React from 'react';
import { NavLink } from 'react-router-dom';

const Sent = () => {
    return (
        <div className="msg-sent">
            <NavLink to="/home" className="sent-front">
                Message envoyé avec succès !<br /><br />
                Cliquez pour retourner à l'accueil
            </NavLink>
            <div className="sent-back"></div>
        </div>
    );
};

export default Sent;