import React from 'react';
import Sent from '../components/Sent';

const MsgSent = () => {
    return (
        <div>
            <Sent />
        </div>
    );
};

export default MsgSent;