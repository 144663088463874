import React from 'react';
import { NavLink } from 'react-router-dom';

import wpLogo from "../assets/imgs/wordpress.png";
import reactLogo from "../assets/imgs/black-react.png";


const Rubrics = () => {
	return (
		<div className="rubrics">
			<ul>
				<li>
					<div className="rubrics-rectangle">
						<NavLink to="/wordpress" className="rubric-front">site en Wordpress
							<div className="wp-logo">
								<img src={wpLogo} alt="logo Wordpress" className="logo-wordpress" />
							</div>
						</NavLink>
						<div className="rubric-back"></div>
					</div>
				</li>
				<li>
					<div className="rubrics-rectangle">
					<NavLink to="/rreact" className="rubric-front">Site en React
						<div className="react-logo">
							<img src={reactLogo} alt="logo react" className="logo-react"/>
						</div>
					</NavLink>
					<div className="rubric-back"></div>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default Rubrics;
