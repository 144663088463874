import React from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const Formular = () => {

	const form = useRef();
	const navigate = useNavigate();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm(
			'service_8ftlp7l',
			'template_k6jxqyc',
			form.current,
			'i9p4RDjllr0yyfV84'
		)
			.then(
				(result) => {
					console.log(result.text);
					console.log("message sent");
					navigate('/msgsent');
				})
				.catch((error) => {
					console.log(error.text);
				});
	};

	return (
		<div>
			<form ref={form} onSubmit={sendEmail} className="leForm">
				<p className="contact-title">Formulaire de contact :</p>
				<label className="name-contact">
					<p className="champ">Nom
						<div className="champ-behind"></div>
					</p>
					<input type="text" name="user_name" className="barre" />
				</label>
				<label className="name-contact">
					<p className="champ">E-mail
						<div className="champ-behind"></div>
					</p>
					<input type="email" name="user_email" className="barre" />
				</label>
				<label className="name-contact">
					<p className="champ">Description
						<div className="champ-behind"></div>
					</p>
					<textarea name="message" className="barre-msg" />
				</label>
					<input type="submit" value="Envoyer" className="send-contact" />
			</form>
		</div>
	);
};

export default Formular;