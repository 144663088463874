import React from 'react';
import Navigation from '../components/Navigation';
import Formular from '../components/Formular';

const Contact = () => {
    return (
        <div>
            <Navigation />
            <Formular />
        </div>
    );
};

export default Contact;