import React from 'react';
import Theme from '../components/Theme';

const Choose = () => {
    return (
        <div>
            <Theme/>
        </div>
    );
};

export default Choose;