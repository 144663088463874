import React from 'react';
import Navigation from '../components/Navigation';
import TarifWp from '../components/TarifWp';

const Wordpress = () => {
    return (
        <div>
            <Navigation />
            <TarifWp />
        </div>
    );
};

export default Wordpress;