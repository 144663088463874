import React from 'react';

import Fr from "../assets/imgs/fr.png";
import En from "../assets/imgs/en.png";
import Es from "../assets/imgs/es.png";

const MeLangage = () => {
    return (
        <div className="langages">
			<p>Je parle :</p>
			<img src={Fr} alt="francais" className="francais" />
			<img src={En} alt="francais" className="francais" />
			<img src={Es} alt="francais" className="esp" />
        </div>
    );
};

export default MeLangage;