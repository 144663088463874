import React from 'react';
import { NavLink } from 'react-router-dom';

import Write from "../assets/imgs/write.png";
import Phone from "../assets/imgs/phone.png";
import Meeting from "../assets/imgs/meeting.png";
import Arms from "../assets/imgs/arms.png";
import Coding from "../assets/imgs/coding.png";
import Check from "../assets/imgs/check.png";
import Website from "../assets/imgs/website.png";
import Garantie from "../assets/imgs/garantie.png";

const Cubes = () => {
	return (
		<div className="organisation">
			<ul>
				<div className="quatuor">
					<li>
						<div className="etape">
							<NavLink to="/contact" className="etape-front">
									<img src={Write} alt="write" className="writing" />
									<p>remplir le formulaire <br />en ligne</p>
							</NavLink>
							<div className="etape-behind"></div>
						</div>
					</li>
					<li>
						<div className="etape">
							<div className="etape-front">
								<img src={Phone} alt="phone" className="phone" />
								<p>premier contact</p>
							</div>
							<div className="etape-behind"></div>
						</div>
					</li>
					<li>
						<div className="etape">
							<div className="etape-front">
								<img src={Meeting} alt="meeting" className="meeting" />
								<p>réalisation du cahier <br />des charges</p>
							</div>
							<div className="etape-behind"></div>
						</div>
					</li>
					<li>
						<div className="etape">
							<div className="etape-front">
								<img src={Arms} alt="arms" className="arms" />
								<p>validation du template <br />et design</p>
							</div>
							<div className="etape-behind"></div>
						</div>
					</li>
				</div>
				<div className="quatuor">
					<li>
						<div className="etape">
							<div className="etape-front">
								<img src={Coding} alt="code" className="coding" />
								<p>developpement du site</p>
							</div>
							<div className="etape-behind"></div>
						</div>
					</li>
					<li>
						<div className="etape">
							<div className="etape-front">
								<img src={Check} alt="check" className="check" />
								<p>validation du site</p>
							</div>
							<div className="etape-behind"></div>
						</div>
					</li>
					<li>
						<div className="etape">
							<div className="etape-front">
								<img src={Website} alt="website" className="website" />
								<p>deploiement du site</p>
							</div>
							<div className="etape-behind"></div>
						</div>
					</li>
					<li>
						<div className="etape">
							<div className="etape-front">
								<img src={Garantie} alt="garantie" className="garantie" />
								<p>garanti 1 mois pour <br />modification</p>
							</div>
							<div className="etape-behind"></div>
						</div>
					</li>
				</div>
			</ul>
		</div>
	);
};

export default Cubes;