import React from 'react';
import Navigation from '../components/Navigation';
import TarifReact from '../components/TarifReact';

const Rreact = () => {
    return (
        <div>
            <Navigation />
            <TarifReact />
        </div>
    );
};

export default Rreact;