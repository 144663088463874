import React from 'react';

import FortyTwo from "../assets/imgs/42.png";
import Globe from "../assets/imgs/globe.png";
import Kids from "../assets/imgs/kids.png";
// import Fr from "../assets/imgs/fr.png";
// import En from "../assets/imgs/en.png";
// import Es from "../assets/imgs/es.png";

const Me = () => {

	return (
		<div className="me">
			<ul>
				<li>
					<div className="me-box">
						<div className="me-front">
							<a href="https://42.fr/">
								<img src={FortyTwo} alt="42" className="img42" />
							</a>
						</div>
						<div className="me-back"></div>
					</div>
					<br /><br /><br />
					<p className="infos">étudiante à l’école 42,<br />passionnée d’informatique</p>
				</li>
				<li>
					<div className="me-box">
						<div className="me-front">
							<img src={Globe} alt="img globe" />
						</div>
						<div className="me-back"></div>
					</div>
					<br /><br /><br />
					<p className="infos">backpackeuse à travers le<br /> monde</p>
				</li>
				<li>
					<div className="me-box">
						<div className="me-front">
							<img src={Kids} alt="img kids" className="imgKids" />
						</div>
						<div className="me-back"></div>
					</div>
					<br /><br /><br />
					<p className="infos">je travaille aussi avec les<br />enfants</p>
				</li>
			</ul>
		</div>
	);
};

export default Me;