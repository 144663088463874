import React from 'react';
import Navigation from '../components/Navigation';
import Differences from '../components/Differences';
import Recommanded from '../components/Recommanded';

const QueChoisir = () => {
    return (
        <div>
            <Navigation />  
            <Differences />
            <Recommanded />
        </div>
    );
};

export default QueChoisir;