import React from 'react';
import { NavLink } from 'react-router-dom';

const Recommanded = () => {
	return (
		<div className="recommanded">
			<ul>
				<li>
					<div className="box">
						<NavLink to="/wordpress" className="recommanded-front">
							<div className="list-wp">
							<p className="wp-title">Recommandé pour Wordpress</p> <br />
							<p>- sites vitrines</p>
							<p>- blogs</p>
							<p>- sites d'entreprise</p>
							<p>- portfolios</p>
							<div className="tarif-box">Tarif</div>
							</div>
						</NavLink>
						<div className="recommanded-behind"></div>
					</div>
				</li>
				<li>
				<div className="box">
						<NavLink to="/rreact" className="recommanded-front">
							<div className="list-wp">
							<p className="wp-title">Recommandé pour React</p> <br />
							<p>- sites vitrines</p>
							<p>- sites d'entreprise</p>
							<p>- applications web interactives</p>
							<p>- plateformes de commerce</p>
							<p>- tableaux de bord dynamiques</p>
							<div className="tarif-box">Tarif</div>
							</div>
						</NavLink>
						<div className="recommanded-behind"></div>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default Recommanded;