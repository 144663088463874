import React from 'react';

const Differences = () => {
    return (
        <div className="differences">
            <p className="diff-title">Differences entre wordpress & react :</p>
            <p className="content">Si vous recherchez une solution conviviale et rapide pour des sites web
                courants, <br /> WordPress peut être plus adapté. Si vous avez besoin de créer
                des applications web <br />interactives ou des sites hautement personnalisés,
                React pourrait être un choix <br /> plus approprié. Ce site est en React.</p>
        </div>
    );
};

export default Differences;