import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Processus from './pages/Processus';
import Quechoisir from './pages/QueChoisir';
import Wordpress from './pages/Wordpress';
import Rreact from './pages/Rreact';
import MsgSent from './pages/MsgSent';
import Choose from './pages/Choose';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Choose />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/processus" element={<Processus />} />
        <Route path="/quechoisir" element={<Quechoisir />} />
        <Route path="/wordpress" element={<Wordpress />} />
        <Route path="/rreact" element={<Rreact />} />
        <Route path="/msgsent" element={<MsgSent />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;